import { createApp } from "vue";
import App from "./App.vue";
import router from './router';
import "./assets/global.css";

import 'flowbite';
import { MotionPlugin } from "@vueuse/motion";
import VueScrollTo from 'vue-scrollto';

const app = createApp(App);
app.use(router);

app.use(MotionPlugin);

app.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  smooth: true,
});

app.mount("#app");

