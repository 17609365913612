<template>
    <main class="bg-white py-10">
      <div class="md:px-10 max-w-screen-2xl mx-auto px-4 py-8">
        <div
          ref="stats"
          class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-5 mb-5"
        >
          <div class="mb-10">
            <h1 class="font-black text-4xl text-center text-blue">
              <span>{{ experience }}</span>+
            </h1>
            <p class="text-sm text-center mt-4 text-[#bdbdbd]">Years Experience</p>
          </div>
          <div class="mb-10">
            <h1 class="font-black text-4xl text-center text-blue">
              <span>{{ designs }}</span>
            </h1>
            <p class="text-sm text-center mt-4 text-[#bdbdbd]">Designs</p>
          </div>
          <div class="mb-10">
            <h1 class="font-black text-4xl text-center text-blue">
              <span>{{ nationsReached }}</span>+
            </h1>
            <p class="text-sm text-center mt-4 text-[#bdbdbd]">Nations Reached</p>
          </div>
          <div class="mb-10">
            <h1 class="font-black text-4xl text-center text-blue">
              <span>{{ followers }}</span>+
            </h1>
            <p class="text-sm text-center mt-4 text-[#bdbdbd]">Followers</p>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    data() {
      return {
        experience: 0,
        designs: 0,
        nationsReached: 0,
        followers: 0,
      };
    },
    methods: {
      animateCounters() {
        const targets = {
          experience: 6,
          designs: 20000,
          nationsReached: 100,
          followers: 50,
        };
  
        Object.keys(targets).forEach((key) => {
          let count = 0;
          const step = Math.ceil(targets[key] / 100);
          const interval = setInterval(() => {
            count += step;
            if (count >= targets[key]) {
              this[key] = targets[key];
              clearInterval(interval);
            } else {
              this[key] = count;
            }
          }, 15); // Adjust speed here
        });
      },
    },
    mounted() {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            this.animateCounters();
            observer.disconnect(); // Trigger animation only once
          }
        },
        { threshold: 0.5 }
      );
  
      observer.observe(this.$refs.stats);
    },
  };
  </script>
  
  <style scoped>
  /* Add any additional styles here */
  </style>
  