<template>
    <main class="bg-white">
      <!-- Container for logo and heading -->
      <div class="md:px-12 max-w-screen-2xl mx-auto text-center py-8">
        <div class="flex items-center justify-center gap-2 mb-4">
          <img class="h-10 w-10" src="../assets/images/gnobonelogo.png" alt="Brand logo" />
          <h2 class="text-sm text-blue font-semibold -ml-2 -mb-8">
              What we do
            </h2>
        </div>
      </div>
  
      <!-- Video Section -->
      <div class="md:px-12 max-w-screen-2xl mx-auto">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 pt-5 pb-10 px-4">
          <iframe
            src="https://www.youtube.com/embed/dW2AyyHYtMo"
            title="YouTube video player"
            frameborder="0"
            class="w-full h-64 sm:h-96 rounded-2xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
  
          <iframe
            src="https://www.youtube.com/embed/Lapwo9Q67c0"
            title="YouTube video player"
            frameborder="0"
            class="w-full h-64 sm:h-96 rounded-2xl"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style scoped>
  /* Add some padding and margin adjustments if needed */
  </style>
  