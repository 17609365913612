<template>
  <main>
    <!-- Preloader will show if loading is true -->
    <preLoader v-if="loading" />

    <!-- Main content will show if loading is false -->
    <div v-else>
      <HeaderSection />
      <RouterView />
      <FooterSection />
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import HeaderSection from './layout/HeaderSection.vue';
import FooterSection from './layout/FooterSection.vue';
import preLoader from './components/preLoader.vue';

// Define loading state
const loading = ref(true);

onMounted(() => {
  setTimeout(() => {
    loading.value = false;
  }, 3000);
});
</script>

<style scoped>
</style>
