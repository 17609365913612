<template>
  <main class="bg-white py-10">
    <!-- Section for architectural designs -->
    <div class="md:px-10 max-w-screen-2xl mx-auto px-4">
      <!-- Header -->
      <div class="flex items-center justify-center">
        <div class="text-center">
          <div class="flex items-center justify-center">
            <img
              class="h-10 w-10"
              src="../assets/images/gnobonelogo.png"
              alt="Brand logo"
            />
            <h2 class="text-sm text-blue font-semibold -mb-7">
              Architectural Designs
            </h2>
          </div>
          <p class="mt-2 text-lg text-gray-600 text-center">
            Explore our stunning architectural designs and get your dream
            project started.
          </p>
        </div>
      </div>

      <!-- Slider for Designs -->
      <div class="md:px-10 max-w-screen-2xl mx-auto py-8">
        <div id="design-carousel" class="relative" data-carousel="slide">
          <!-- Carousel wrapper -->
          <div class="relative overflow-hidden rounded-lg lg:py-10">
            <!-- Carousel container -->
            <div
              class="flex gap-6 transition-transform duration-700 ease-in-out"
              :style="{
                transform: `translateX(-${
                  activeIndex * (100 / cardsPerSlide)
                }%)`,
              }"
            >
              <!-- Design Cards (converted to slider) -->
              <div
                v-for="(design, index) in designs"
                :key="design.id"
                class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 min-w-[80%] sm:min-w-[50%] md:min-w-[33.33%] lg:min-w-[30%] cursor-pointer text-center py-4 px-4 rounded-lg shadow-lg transition-all duration-500 hover:scale-105 hover:shadow-xl"
              >
                <!-- Design image -->
                <img
                  :src="design.image"
                  alt="Design Image"
                  class="w-full h-36 object-cover rounded-md mb-4 transition-transform duration-300 hover:scale-105"
                />

                <!-- Design name and description -->
                <h3 class="text-xl font-bold text-center text-main_blue mb-2">
                  {{ design.name }}
                </h3>
                <p class="text-center text-gray-700 mb-4">
                  {{ design.description }}
                </p>

                <!-- Buttons for contacting and payment -->
                <div class="flex flex-col lg:flex-row gap-4 items-center justify-between mt-10">
                  <a
                    :href="
                      'https://wa.me/?text=' +
                      encodeURIComponent('I am interested in ' + design.name)
                    "
                    target="_blank"
                    class="w-full bg-[#859aca] shadow-md text-white mb-2 px-6 py-2 rounded-full text-sm transition-transform hover:bg-gray-500 hover:scale-105 focus:outline-none flex items-center justify-center"
                  >
                    Purchase
                  </a>
                  <a
                    :href="design.paymentLink"
                    target="_blank"
                    class="w-full bg-blue shadow-md text-white px-6 py-2 rounded-full text-sm transition-transform hover:scale-105 focus:outline-none flex items-center justify-center"
                  >
                    Pay in USD
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- Slider controls -->
          <button
            type="button"
            class="absolute top-1/2 left-0 transform -translate-y-1/2 z-30 flex items-center justify-center h-10 w-10 bg-blue rounded-full"
            @click="prevSlide"
          >
            <svg
              class="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            class="absolute top-1/2 right-0 transform -translate-y-1/2 z-30 flex items-center justify-center h-10 w-10 bg-blue rounded-full"
            @click="nextSlide"
          >
            <svg
              class="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      cardsPerSlide: 3, // Adjust according to the number of cards per slide (3 cards in this case)
      designs: [
        {
          id: 1,
          name: "Four Bedroom Penthouse",
          description:
            "A contemporary 4-bedroom duplex a pent house offers a total floor area of 295 sqm.",
          price: 1200,
          image: require("../assets/images/3d-exterior.jpg"),
          paymentLink: "https://archafricabureau.com/product/four-bedroom-penthouse/",
        },
        {
          id: 2,
          name: "Residential Villa",
          description:
            "A contemporary 5-bedroom duplex a total floor area of 168.5 sqm, including a main lounge and more.",
          price: 1500,
          image: require("../assets/images/residential-villa.jpg"),
          paymentLink: "https://archafricabureau.com/product/residential-villa-3/",
        },
        {
          id: 3,
          name: "7 Bedroom Residential Villa",
          description: "A contemporary 7-bedroom duplex offers a total floor area of 188.60 sqm, including a main lounge and more.",
          price: 900,
          image: require("../assets/images/7-bedroom.jpg"),
          paymentLink: "https://archafricabureau.com/product/7-bedroom-residential-villa/",
        },
        {
          id: 4,
          name: "7 Bedroom Semi-detached",
          description: "A contemporary 7-bedroom semi-detached duplex offers a total floor area of 414.62 sqm.",
          price: 600,
          image: require("../assets/images/payment.jpg"),
          paymentLink: "https://archafricabureau.com/product/7-bedroom-semi-detached-residential-villa/",
        },
        {
          id: 5,
          name: "7 Bedroom Duplex",
          description: "A contemporary 7-bedroom duplex offers a total floor area of 295.320 sqm, including a main lounge.",
          price: 330,
          image: require("../assets/images/7-bedroom-duplex.jpg"),
          paymentLink: "https://archafricabureau.com/product/7-bedroom-duplex-residential-villa/",
        },
        {
          id: 6,
          name: "4 Star Luxury Hotel",
          description: "Four-star Hotel,  42 rooms, Gym, swimming pool, Conference rooms, and on-site restaurant services.",
          price: 300,
          image: require("../assets/images/4-star.jpg"),
          paymentLink: "https://archafricabureau.com/product/4-star-luxury-hotel/",
        },
        {
          id: 7,
          name: "5 Bedroom BQ",
          description: "A contemporary 5-bedroom duplex with BQ offers a total floor area of 177.16sqm, including a main lounge and BQ.",
          price: 100,
          image: require("../assets/images/5-bedroom-bq.jpg"),
          paymentLink: "https://archafricabureau.com/product/5-bedroom-bq-residential-villa/",
        },
      ],
      slideInterval: null, // To store interval for automatic sliding
    };
  },
  mounted() {
    // Set up automatic slide change every 3 seconds
    this.slideInterval = setInterval(this.nextSlide, 3000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.slideInterval);
  },
  methods: {
    nextSlide() {
      if (
        this.activeIndex <
        Math.ceil(this.designs.length / this.cardsPerSlide) + 1
      ) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0; // Go back to the first set of slides
      }
    },
    prevSlide() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex =
          Math.ceil(this.designs.length / this.cardsPerSlide) - 1; // Go to the last set of slides
      }
    },
  },
};
</script>

<style scoped>
/* Custom styling */
.carousel-wrapper {
  display: flex;
  gap: 6px;
  transition: transform 0.5s ease-in-out;
}

img {
  transition: transform 0.3s ease-in-out;
}

img:hover {
  transform: scale(1.05);
}
</style>
