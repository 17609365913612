<template>
  <footer class="relative bg-blue text-white py-4">
    <!-- Content -->
    <div class="relative z-10 max-w-screen-xl mx-auto px-6 md:px-12 lg:px-16">
      <!-- Upper Contact Us section -->
      <div class="flex justify-center mb-10 lg:-mt-24">
        <div class="w-4/5 lg:w-3/5 bg-[#859aca] rounded-xl p-8 shadow-lg text-center">
          <p class="text-2xl font-semibold text-white mb-2">Ready to get started?</p>
          <p class="text-gray-300 mb-4">Talk to us today</p>
          <a href="#"
             class="inline-flex items-center bg-blue text-white font-medium py-3 px-6 rounded-full shadow-lg transition-transform">
            Let's Talk Online
            <i class="fa-regular fa-circle-check ml-2"></i>
          </a>
        </div>
      </div>

      <!-- Main footer section -->
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
        <!-- Logo Section -->
        <div>
          <a href="/">
            <img src="../assets/icons/blackwhite-logo.png" class=" h-36 mx-auto lg:mx-0" alt="Gnobone Visions Logo" />
          </a>
        </div>

        <!-- Links -->
        <div>
          <h2 class="text-lg font-semibold text-gray-300 uppercase mb-4">Resources</h2>
          <ul class="space-y-2">
            <li><a href="https://flowbite.com/" class="text-gray-400 hover:text-white transition">Flowbite</a></li>
            <li><a href="https://tailwindcss.com/" class="text-gray-400 hover:text-white transition">Tailwind CSS</a></li>
          </ul>
        </div>

        <div>
          <h2 class="text-lg font-semibold text-gray-300 uppercase mb-4">Follow Us</h2>
          <ul class="space-y-2">
            <li><a href="https://github.com/themesberg/flowbite" class="text-gray-400 hover:text-white transition">Github</a></li>
            <li><a href="https://discord.gg/4eeurUVvTy" class="text-gray-400 hover:text-white transition">Discord</a></li>
          </ul>
        </div>

        <div>
          <h2 class="text-lg font-semibold text-gray-300 uppercase mb-4">Legal</h2>
          <ul class="space-y-2 ">
            <li><a href="#" class="text-gray-400 hover:text-white transition">Privacy Policy</a></li>
            <li><a href="#" class="text-gray-400 hover:text-white transition">Terms &amp; Conditions</a></li>
          </ul>
        </div>
      </div>

      <!-- Divider -->
      <hr class="border-white my-3" />

      <!-- Social Media and Footer Note -->
      <div class="flex flex-col lg:flex-row justify-between items-center text-gray-400">
        <span>© 2024 <a href="/" class="hover:text-white">Gnobone Visions™</a>. All Rights Reserved.</span>
        <div class="flex space-x-6 mt-4 lg:mt-0">
          <a href="#" class="hover:text-white transition"><i class="fab fa-facebook-f"></i><span class="sr-only">Facebook</span></a>
          <a href="#" class="hover:text-white transition"><i class="fab fa-discord"></i><span class="sr-only">Discord</span></a>
          <a href="#" class="hover:text-white transition"><i class="fab fa-twitter"></i><span class="sr-only">Twitter</span></a>
          <a href="#" class="hover:text-white transition"><i class="fab fa-github"></i><span class="sr-only">GitHub</span></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  font-family: 'Inter', sans-serif;
}

footer a {
  transition: color 0.3s ease;
}

</style>
