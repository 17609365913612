<template>
  <header class="header-overlay py-4 relative flex justify-center">
    <!-- Left Design Elements (Hidden on mobile) -->
    <div class="absolute left-0 top-1/2 transform -translate-y-1/2 ml-6 hidden md:flex space-x-4">
      <div class="flex items-center">
        <div class="floating bg-blue cursor-pointer shadow-md w-10 h-10 rounded-full flex items-center justify-center">
          <span class="text-lg text-white"><i class="fa-solid fa-house"></i></span>
        </div>
      </div>
    </div>

    <!-- Center: Logo and Navigation Links -->
    <div class="flex justify-between items-center bg-transparent lg:px-1 px-4 py-1 rounded-2xl w-full md:w-[80%]">
      <!-- Logo Section -->
      <div class="flex items-center space-x-2">
        <img src="../assets/images/blackwhite-logo.png" alt="Gnobone Visions" class=" h-16" />
      </div>

      <!-- Mobile Menu Button -->
      <button @click="toggleMenu" class="md:hidden focus:outline-none">
        <i v-if="!isMenuOpen" class="fa-solid fa-bars"></i>
        <i v-else class="fa-solid fa-xmark"></i>
      </button>

      <!-- Navigation Links (Responsive) -->
      <nav :class="isMenuOpen ? 'block' : 'hidden'" class="absolute top-full left-0 w-full bg-white shadow-lg rounded-lg md:static md:flex md:w-auto md:bg-transparent md:shadow-none z-10 transition-all duration-300">
        <div class="flex flex-col md:flex-row md:space-x-6 items-center text-sm p-6 md:p-0">
          <a href="#" class="text-white hover:text-blue font-semibold py-2">Home</a>
          <a href="#" class="text-white hover:text-blue py-2">About</a>
          <a href="#" class="text-white hover:text-blue py-2">Services</a>
          <a href="#" class="text-white hover:text-blue py-2">Contact us</a>

          <!-- Call to Action Button (Visible on mobile and desktop) -->
          <div class="mt-4 md:mt-0">
            <button class="bg-blue shadow-md text-white px-5 py-2 rounded-full border border-none font-semibold flex items-center space-x-2 hover:bg-blue transition-transform hover:scale-110 focus:outline-none">
              <span>Let’s Talk</span>
              <span>✔</span>
            </button>
          </div>
        </div>
      </nav>
    </div>

    <!-- Right Design Element (Hidden on mobile) -->
    <div class="absolute right-0 top-1/2 transform -translate-y-1/2 mr-6 hidden md:flex">
      <div class="flex items-center">
        <div class="floating cursor-pointer bg-blue shadow-md w-10 h-10 rounded-full flex items-center justify-center">
          <span class="text-lg text-white"><i class="fa-solid fa-pencil"></i></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
.header-overlay {
  background-color: transparent; /* Makes header background transparent */
  position: absolute; /* Positions the header absolutely */
  top: 0;
  width: 100%;
  z-index: 20; /* Ensures the header is above the video */
}

.header-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.floating {
  animation: float 6s ease-in-out infinite;
}

.hover\:shadow-glow:hover {
  animation: glow 0.8s ease-in-out infinite alternate;
}

.floating:nth-child(2) {
  animation-duration: 5s;
}
.floating:nth-child(3) {
  animation-duration: 4s;
}
.floating:nth-child(4) {
  animation-duration: 7s;
}
</style>
