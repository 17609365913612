<template>
  <main class="bg-gray-50 py-12">
    <div ref="servicesSection" class="md:px-10 max-w-screen-2xl mx-auto px-4 services-section">
      <div class="flex flex-col lg:flex-row justify-between">
        <div class="text-start">
          <div class="flex items-center">
            <img class="h-10 w-10" src="../assets/images/gnobonelogo.png" alt="Brand logo" />
            <h2 class="text-sm text-blue font-semibold -mb-8">Our Services</h2>
          </div>
        </div>

        <div class="mt-4 lg:mt-0">
          <a href="#" class="inline-flex items-center transition-transform hover:scale-105 focus:outline-none px-6 py-3 bg-blue text-white rounded-full shadow-md hover:bg-[#859aca] text-sm">
            Download Projects
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="ml-2 h-5 w-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </a>
        </div>
      </div>

      <h1 class="text-2xl font-bold text-gray-800 mt-6 lg:mt-2">The process that makes <br> your life easier.</h1>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-16">
        <div v-for="(service, index) in services" :key="index" class="service-card bg-white rounded-lg shadow-md hover:shadow-black hover:transition-all hover:duration-300 text-start py-8 px-4">
          <img :src="service.icon" :alt="service.alt" class="h-14 w-16"/>
          <h3 class="text-base font-bold mt-4">{{ service.title }}</h3>
          <p class="text-gray-500 mt-2 text-sm">{{ service.description }}</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
  setup() {
    const servicesSection = ref(null);
    const services = ref([
      { icon: require('../assets/icons/architecture.png'), alt: 'architecture image', title: 'Architect', description: 'We let you to be always connect with anyone through a social the media network.' },
      { icon: require('../assets/icons/data-transfer.png'), alt: 'data transfer', title: 'Designer', description: 'We let you to be always connect with anyone through a social the media network.' },
      { icon: require('../assets/icons/organization.png'), alt: 'organization', title: 'Architectural Designer', description: 'We let you to be always connect with anyone through a social the media network.' },
      { icon: require('../assets/icons/skyscrapers.png'), alt: 'skyscrapers', title: 'Design Architect', description: 'We let you to be always connect with anyone through a social the media network.' },
    ]);

    onMounted(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          } else {
            entry.target.classList.remove('fade-in');
          }
        });
      }, {
        threshold: 0.2, // Lower threshold for smoother animation start
      });

      document.querySelectorAll('.service-card').forEach((card) => observer.observe(card));
    });

    return {
      servicesSection,
      services,
    };
  },
};
</script>

<style scoped>
.service-card {
  opacity: 0;
  transform: translateY(50px); /* Reduced translate distance */
  transition: opacity 1s ease-out, transform 1s ease-out;
  will-change: opacity, transform; /* Performance optimization */
}

.service-card.fade-in {
  opacity: 1;
  transform: translateY(0px);
}
</style>
