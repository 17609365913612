<template>
    <div class="preloader">
      <!-- Add your preloader animation or image here -->
      <img src="../assets/images/logo3.gif" alt="Loading..." />
    </div>
  </template>
  
  <script>
  export default {
  };
  </script>
  
  <style scoped>
  .preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e1e1e4;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  </style>
  